<script>
import hash from 'js-sha512';


var BASE_URL = "https://api.trustsignals.ai/";
var AJAX = {
    REQUEST: function (endpoint, input_method, input_data, success_callback, error_callback) {
        return window.$.ajax({
            timeout: 300000,
            url: BASE_URL + endpoint,
            data: input_data == null ? null : JSON.stringify(input_data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.PUMPMOB_TOKEN);
            },
            method: input_method,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            complete: function (xhr) {

            },
            success: function (data) {
                success_callback(data);
            },
            error: function (data) {
                if (data.status == 401) { // Non autorizzato, cancella il token e rimanda al login
                    window.localStorage.removeItem("PUMPMOB_TOKEN");
                    window.router.push("/login");
                } else if (data.status == 502 || data.status == 503 || data.status == 504) { // Backend offline
                    API.error(undefined, "Service unavailable");
                } else if (data.status == 404) { // Endpoint non valido
                    API.error(undefined, "Invalid endpoint");
                } else {
                    error_callback(data);
                }
            }
        });

    }
};

var API = {

    /*
        TOOLS
    */

    validate: function (payload_t, payload_d) {
        for (var k in payload_t) {
            if (payload_d[k] != undefined && payload_d[k] != "undefined" || ['CreatedAt', 'UpdatedAt', 'DeletedAt'].includes(k)) {
                payload_t[k] = payload_d[k];
            } else if (payload_t[k]) { // Parametro obbligatorio
                console.error(k + " parameter is required.");
                return null;
            }

        }
        return payload_t;
    },

    error: function (d, ctitle = null, cmsg = null, type = "notify") {
        var msg = cmsg == null ? "Unable to process your request, please try again later." : cmsg;
        var title = ctitle == null ? "Error" : ctitle;
        if (typeof d == "object") {
            if (d.responseJSON != undefined) {
                if (d.responseJSON.message != undefined) {
                    switch (type) {
                        case "notify":
                            msg = d.responseJSON.message;
                            title = "Error 0x" + d.responseJSON.status.toString().padStart(5, "0");
                            break;
                        case "swal":
                            msg = d.responseJSON.message + "</br><small>Code:" + "0x" + d.responseJSON.status.toString().padStart(5, "0") + "</small>";
                            break;
                    }
                }
            }

            if (d.statusText == "timeout") {
                msg = "Request timed out, try again later.";
            }
        }

        switch (type) {
            case "notify":
                API.notify_error(title, msg);
                break;
            case "swal":
                API.swal_error(msg);
                break;
        }
    },

    swal_error: function (msg) {
        window.swal.fire("Error!", msg, "error");
    },

    notify_error: function (title, msg) {
        window.toastr.error(msg, title, {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": true,
            "preventOpenDuplicates": true,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "50000",
            "extendedTimeOut": "8000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        });
    },

    notify_success: function (title, msg) {
        window.toastr.success(msg, title, {
            "closeButton": true,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": true,
            "preventOpenDuplicates": true,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        });
    },

    success: function (d) {
        window.swal.fire("Done!", d.message, "success");
    },

    escapeHtml: function (text) {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };

        return text.replace(/[&<>"']/g, function (m) {
            return map[m];
        });
    },

    unescapeHtml: function (text) {
        var map = {
            '&amp;': '&',
            '&lt;': '<',
            '&gt;': '>',
            '&quot;': '"',
            '&#039;': "'"
        };

        for (var k in map) {
            text = text.replace("/" + k + "/g", map[k]);
        }
        return text;

    },

    __context: {
        TABLE: 'table',
        FORM: 'form'
    },

    contextualize: function (ent, obj, src, dst, ctx, callback = undefined) {
        // ent -> entry type (eg. user/vm)
        // obj -> entry
        // src -> api/gui
        // dst -> api/gui
        // ctx -> TABLE/FORM, used for api->gui only
        var out = {
            api: null,
            gui: null,
            placeholders: {}
        };
        out[src] = obj;
        out[dst] = new Object();
        var wrapper = API.administration[ent].__model;
        if (wrapper == undefined) {
            console.error("Contextualize: unable to get wrapper for entity " + ent);
            return;
        }
        out['childs'] = API.administration[ent].__childs;
        if (out['childs'] == undefined) {
            out['childs'] = {};
        }

        var done_calls = 0;
        var total_calls = 0;

        if (src == 'api' && dst == 'gui') {
            switch (ctx) {
                case API.__context.TABLE:
                    for (var k in wrapper) {
                        if (!wrapper[k].Contexts.includes(API.__context.TABLE)) {
                            continue;
                        }

                        out[dst][k] = {
                            Value: wrapper[k].Formatter(obj[k], obj),
                            Placeholder: wrapper[k].Placeholder,
                            Disabled: wrapper[k].Disabled == true,
                            Type: wrapper[k].Type,
                        };
                        out.placeholders[k] = wrapper[k].Placeholder;

                        if (wrapper[k].Suffix != undefined) {
                            out[dst][k].Value += ' ' + wrapper[k].Suffix;
                        }
                    }
                    break;

                case API.__context.FORM:
                    for (var z in wrapper) {
                        if (!wrapper[z].Contexts.includes(API.__context.FORM)) {
                            continue;
                        }

                        if (wrapper[z].Type == 'dropdown') {
                            total_calls++;
                        }
                    }
                    for (z in wrapper) {
                        if (!wrapper[z].Contexts.includes(API.__context.FORM)) {
                            continue;
                        }

                        out[dst][z] = {
                            Value: obj[z] != undefined ? wrapper[z].Formatter(obj[z], obj) : '',
                            Placeholder: wrapper[z].Placeholder,
                            Disabled: wrapper[z].Disabled == true,
                            Type: wrapper[z].Type,
                        };

                        if (wrapper[z].Type == 'dropdown') {

                            out[dst][z].DTitle = wrapper[z].Source.Title;
                            out[dst][z].DValue = wrapper[z].Source.Value;
                            out[dst][z].DMultiple = wrapper[z].Source.Multiple;
                            console.log(out[dst][z].Value)
                            if (Array.isArray(out[dst][z].Value)) {
                                out[dst][z].Value = [];
                            }

                            var l = function (index) {
                                wrapper[index].Source.Get(function (d) {
                                    out[dst][index].DOptions = d[wrapper[index].Source.ResponseField];
                                    done_calls++;
                                    if (callback != undefined && total_calls == done_calls) {
                                        callback(out);
                                        console.log(out)
                                    }

                                }, function (d) {
                                    API.error(undefined, "Unable to fetch childs data.");
                                });
                            }

                            l(z);

                        }
                        out.placeholders[z] = wrapper[z].Placeholder;
                    }
                    break;

                default:
                    console.error("Contextualize: invalid context");
                    break;

            }
        } else if (src == 'gui' && dst == 'api') {
            console.log(wrapper)
            for (var x in wrapper) {
                if (wrapper[x].Disabled) {
                    continue;
                }
                out[dst][x] = obj.gui[x] != undefined ? wrapper[x].Transformer(obj.gui[x].Value) : ''
            }
        } else {
            console.error("Contextualize: invalid src/dst");
            return;
        }

        return out;
    },

    /*
        SIGNALS
    */

    signal: {

        get: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/signals",
                "POST",
                {
                    From: 0,
                    To: 9999,
                    Signal: {}
                },
                s_callback,
                e_callback
            );
        },

    },

    order: {

        get: function (data, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/orders",
                "POST",
                {
                    From: data.From,
                    To: data.To,
                    Order: {}
                },
                s_callback,
                e_callback
            );
        },

        top: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/orders/top",
                "GET",
                undefined,
                s_callback,
                e_callback
            );
        },

        sell: function (data, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/orders/" + data.Id + "/sell",
                "POST",
                {
                    
                },
                s_callback,
                e_callback
            );
        },

    },

    /*
        DEFI
    */

    defi: {
        initDeposit: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/deposit",
                "PUT",
                undefined,
                s_callback,
                e_callback
            );
        },

        confirmDeposit: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/deposits/" + payload.ID + "/confirm",
                "POST",
                API.validate({
                    TxID: true
                }, payload),
                s_callback,
                e_callback
            );
        },

        getDeposits: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/transactions",
                "GET",
                undefined,
                s_callback,
                e_callback
            );
        },

        withdraw: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/withdraw",
                "PUT",
                API.validate({
                    Amount: true,
                    Address: true
                }, payload),
                s_callback,
                e_callback
            );
        },

        
    },

    plans: {
        get: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/plans",
                "POST",
                {
                    Plan: {},
                    From: 0,
                    To: 10
                },
                s_callback,
                e_callback
            );
        },

        buy: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/plans/" + payload.Id,
                "PUT",
                {
                    Months: 1,
                },
                s_callback,
                e_callback
            );
        },

        buy_annual: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/plans/" + payload.Id,
                "PUT",
                {
                    Months: 12,
                },
                //undefined,
                s_callback,
                e_callback
            );
        },

    },

    /*
        USER
    */

    user: {
        reset: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/password",
                "POST",
                API.validate({
                    Email: true,
                    RecaptchaResponse: true
                }, payload),
                s_callback,
                e_callback
            );
        },

        firebaseToken: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/token",
                "POST",
                API.validate({
                    Token: true
                }, payload),
                s_callback,
                e_callback
            );
        },

        recover: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/password/reset",
                "POST",
                API.validate({
                    Email: true,
                    Token: true,
                    Password: true,
                    RecaptchaResponse: true,
                   
                }, payload),
                s_callback,
                e_callback
            );
        },
        
        login: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/login",
                "POST",
                API.validate({
                    Email: true,
                    Password: true,
                    Duration: true,
                    TwoFA: undefined
                }, payload),
                s_callback,
                e_callback
            );
        },

        get: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users",
                "GET",
                undefined,
                s_callback,
                e_callback
            );
        },

        get_current_time: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/time",
                "GET",
                undefined,
                s_callback,
                e_callback
            );
        },

        stats: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/stats",
                "POST",
                 API.validate({
                    From: true,
                    To: true
                }, payload),
                s_callback,
                e_callback
            );
        },

        upgrade: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/upgrade",
                "POST",
                API.validate({
                    Method: true
                }, payload),
                s_callback,
                e_callback
            );
        },

        referred_transactions: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/referred/transactions",
                "GET",
                undefined,
                s_callback,
                e_callback
            );
        },

        plans_prices: function (s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/price",
                "GET",
                undefined,
                s_callback,
                e_callback
            );
        },

        register: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/register",
                "PUT",
                API.validate({
                    Email: true,
                    Password: true,
                    RecaptchaResponse: true,
                    Referer: undefined
                }, payload),
                s_callback,
                e_callback
            );
        },

        redeem: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users/redeem",
                "POST",
                API.validate({
                    Amount: undefined,
                    Type: true,
                    Address: undefined,
                    Coin: undefined
                }, payload),
                s_callback,
                e_callback
            );
        },

        edit: function (payload, s_callback, e_callback) {
            AJAX.REQUEST(
                "v1/api/users",
                "PATCH",
                API.validate({
                    User: undefined,
                    OldPassword: undefined,
                    NewPassword: undefined
                }, payload),
                s_callback,
                e_callback
            );
        },

    },

    // EndPoint Admin
    administration: {

        campaign: {
            RequestField: 'Campaign',
            ResponseField: 'campaigns',
            Singular: 'campaign',
            Plural: 'campaigns',

            __model: {

                ID: {
                    Placeholder: 'ID',
                    Type: 'text',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Name: {
                    Placeholder: 'Name',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Status: {
                    Placeholder: 'Status',
                    Type: 'text',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                StartTime: {
                    Placeholder: 'Start time',
                    Type: 'datetime',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        var d = new Date(val);
                        return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                From: {
                    Placeholder: 'From',
                    Type: 'text',
                    Contexts: ['form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val.toLowerCase();
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                MailPerHour: {
                    Placeholder: 'Mail per hour',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return parseInt(val);
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Groups: {
                    Placeholder: 'Groups',
                    Type: 'dropdown',
                    Contexts: ['form'],
                    Disabled: false,
                    Source: {
                        Get: function (s_callback, e_callback) {
                            AJAX.REQUEST(
                                "v1/api/groups",
                                "POST", {
                                    From: 0,
                                    To: 5000,
                                },
                                s_callback,
                                e_callback
                            )
                        },
                        ResponseField: 'groups',
                        Title: 'Name',
                        Value: 'ID',
                        Multiple: true
                    },

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Template: {
                    Placeholder: 'Template',
                    Type: 'dropdown',
                    Contexts: ['form'],
                    Disabled: false,
                    Source: {
                        Get: function (s_callback, e_callback) {
                            AJAX.REQUEST(
                                "v1/api/templates",
                                "POST", {
                                    From: 0,
                                    To: 1000,
                                },
                                s_callback,
                                e_callback
                            )
                        },
                        ResponseField: 'templates',
                        Title: 'Subject',
                        Value: 'ID',
                        Multiple: false
                    },

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Sent: {
                    Placeholder: 'Percentage',
                    Type: 'text',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        var perc = (obj.Sent/obj.Total*100).toFixed(2);
                        if(isNaN(perc)){
                            perc = (0).toFixed(2);
                        }
                        return obj.Sent + '/' + obj.Total + ' (' + perc + '%)';
                    },

                    Transformer: function (val) {
                        return parseInt(val);
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

            },

            get: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/campaigns",
                    "POST",
                    API.validate({
                        From: true,
                        To: true,
                        Campaign: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            new: function (payload, s_callback, e_callback) {
                payload.Template = payload.Campaign.Template;
                payload.Campaign.Template = undefined;
                payload.Contact = new Object();
                payload.Contact.Groups = payload.Campaign.Groups;
                payload.Campaign.Groups = undefined;
                AJAX.REQUEST(
                    "v1/api/campaigns",
                    "PUT",
                    API.validate({
                        Campaign: true,
                        Contact: true,
                        Template: true,
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            edit: function (payload, s_callback, e_callback) {
                payload.Template = payload.Campaign.Template;
                payload.Campaign.Template = undefined;
                payload.Contact = new Object();
                payload.Contact.Groups = payload.Campaign.Groups;
                payload.Campaign.Groups = undefined;
                AJAX.REQUEST(
                    "v1/api/campaigns",
                    "PATCH",
                    API.validate({
                        Campaign: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            delete: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/campaigns/" + payload.Campaign.ID,
                    "DELETE",
                    undefined,
                    s_callback,
                    e_callback
                );
            },
        },

        template: {
            RequestField: 'Template',
            ResponseField: 'templates',
            Singular: 'template',
            Plural: 'templates',

            __model: {

                ID: {
                    Placeholder: 'ID',
                    Type: 'text',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Subject: {
                    Placeholder: 'Subject',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Body: {
                    Placeholder: 'Body',
                    Type: 'html',
                    Contexts: ['form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

            },

            get: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/templates",
                    "POST",
                    API.validate({
                        From: true,
                        To: true,
                        Template: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            new: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/templates",
                    "PUT",
                    API.validate({
                        Template: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            edit: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/templates",
                    "PATCH",
                    API.validate({
                        Template: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            delete: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/templates/" + payload.Template.ID,
                    "DELETE",
                    undefined,
                    s_callback,
                    e_callback
                );
            },
        },

       
        user: {
            RequestField: 'User',
            ResponseField: 'users',
            Singular: 'user',
            Plural: 'users',

            __model: {
                ID: {
                    Placeholder: 'ID',
                    Type: 'text',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Email: {
                    Placeholder: 'Email',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Administrator: {
                    Placeholder: 'Rank',
                    Type: 'bool',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        if (val) {
                            return 'Admin';
                        } else {
                            return 'User';
                        }
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Password: {
                    Placeholder: 'Password',
                    Type: 'text',
                    Contexts: ['form'],
                    Disabled: false,
                    CreateOnly: true,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return hash.sha512(val);
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

            },

            get: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/users",
                    "POST",
                    API.validate({
                        From: true,
                        To: true,
                        User: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },

            


            new: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/users",
                    "PUT",
                    API.validate({
                        User: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            edit: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/users",
                    "PATCH",
                    API.validate({
                        User: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            delete: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/users/" + payload.User.ID,
                    "DELETE",
                    undefined,
                    s_callback,
                    e_callback
                );
            },
        },

        contact: {
            RequestField: 'Contact',
            ResponseField: 'contacts',
            Singular: 'contact',
            Plural: 'contacts',

            __model: {

                ID: {
                    Placeholder: 'ID',
                    Type: 'text',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Email: {
                    Placeholder: 'Email',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Name: {
                    Placeholder: 'Name',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Surname: {
                    Placeholder: 'Surname',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Groups: {
                    Placeholder: 'Groups',
                    Type: 'dropdown',
                    Contexts: ['form'],
                    Disabled: false,
                    Source: {
                        Get: function (s_callback, e_callback) {
                            AJAX.REQUEST(
                                "v1/api/groups",
                                "POST", {
                                    From: 0,
                                    To: 1000,
                                },
                                s_callback,
                                e_callback
                            )
                        },
                        ResponseField: 'groups',
                        Title: 'Name',
                        Value: 'ID',
                        Multiple: true
                    },

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

            },

            get: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/contacts",
                    "POST",
                    API.validate({
                        From: true,
                        To: true,
                        Contact: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            new: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/contacts",
                    "PUT",
                    API.validate({
                        Contact: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            edit: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/contacts",
                    "PATCH",
                    API.validate({
                        Contact: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            delete: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/contacts/" + payload.Contact.ID,
                    "DELETE",
                    undefined,
                    s_callback,
                    e_callback
                );
            },
        },

        group: {
            RequestField: 'Group',
            ResponseField: 'groups',
            Singular: 'group',
            Plural: 'groups',

            __model: {

                ID: {
                    Placeholder: 'ID',
                    Type: 'text',
                    Contexts: ['table'],
                    Disabled: true,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                },

                Name: {
                    Placeholder: 'Name',
                    Type: 'text',
                    Contexts: ['table', 'form'],
                    Disabled: false,

                    Formatter: function (val, obj) {
                        return val;
                    },

                    Transformer: function (val) {
                        return val;
                    },

                    Validate: function (val, obj) {
                        return true;
                    }
                }

            },

            get: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/groups",
                    "POST",
                    API.validate({
                        From: true,
                        To: true,
                        Group: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            new: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/groups",
                    "PUT",
                    API.validate({
                        Group: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            edit: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/groups",
                    "PATCH",
                    API.validate({
                        Group: true
                    }, payload),
                    s_callback,
                    e_callback
                );
            },
            delete: function (payload, s_callback, e_callback) {
                AJAX.REQUEST(
                    "v1/api/groups/" + payload.Group.ID,
                    "DELETE",
                    undefined,
                    s_callback,
                    e_callback
                );
            },
        },
    },

}

export default {
    api: API,
    base_url: BASE_URL,
}
</script>
